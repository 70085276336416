import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  FaHome,
  FaUserSecret,
  FaBook,
  FaEnvelope,
} from 'react-icons/fa'

const Sidebar = styled.div`
  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 20%;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 20%;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    width: 10%;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: '';
      margin: 10px;
    }
  }

  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #193549;
  color: #dcdcdc;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 80px;
    height: 80px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 80px;
    height: 80px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }

  width: 152px;
  height: 152px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledHref = styled.a`
  text-decoration: none;
  color: inherit;
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 10px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    div {
      margin: 0px;
    }
    span {
      display: none;
    }
  }
`

const SiteTitle = styled.h1`
  display: flex;

  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 16px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const NameBoard = styled.h5`
  display: flex;

  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 12px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 12px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const SidebarComponent = ({ title, authorName }) => (
  <Sidebar>
    <StyledLink to="/">
      <SiteTitle>{title}</SiteTitle>
    </StyledLink>
    <Logo
      src="data:image/png;base64,iVBORw0KGgoAAAANS    UhEUgAAAGQAAABkAgMAAAANjH3HAAAABGdBTUEAALGPC/xhBQAAACBjSFJN 
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACVBMVEX///9gHZD///9TKzKC 
AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfj 
CxwUKC0ytuPJAAABjklEQVRIx42Wy5nFIAhGzcIS0k+mgyyk/1bmyktE/jvjLjl8nEQFbTRaNW5q 
RCWhSZ4CXEzegnQmlehmUolIyCm6lJyirmRUGiZUaYQ8hUbIW2iEjEIjhArNh4xDNDVzrsedRR/N 
YNKzaIYyubJopmeSp44jhSQRZxeSRBwoJIk4uZBdJHFKNpHkVrKJJEzJJpLUSqJIo4wEkWY2EkQa 
ZCSINLGRJbIYJy6yvE5cZCFOXGRpndgbj1hEs4SsRvwH9UsW0TT+9YvIu/XHgXCeNUuB6L6wmQ3E 
CvA5yHz7syY2Ei6N8LQIl5MvYCRcgr7okew1tpE71uVGeqzljVxxE/+T4Gz4C+BX4z/Fs4NnFK4C 
Xjm82niHwF2FdyLevXjHwyrBlYWrEVcwrHrcKXB3wR0JdjHZAms8sVtu440ddhurw1IeRq6DWCfv 
B7Hufx/ETgx4yuCTCZ9m+ASEpyZljZ+09O10fhPpTp5E1i2gtUL0/bbxHqTvBZinrtLYTQjfnt6C 
9D9uaa0aFHtVEv0Cw2Q5EjINokkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMTEtMjhUMjA6NDA6 
NDUrMDA6MDBGfT/rAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTExLTI4VDIwOjQwOjQ1KzAwOjAw 
NyCHVwAAAABJRU5ErkJggg==" 
      alt={authorName}
    />
    <NameBoard>The personal website of Max Bonnefin</NameBoard>
    <MenuWrapper>
      <div>
        <p>
          <StyledLink to="/">
            <FaHome /> <span>Home</span>
          </StyledLink>
        </p>
        <p>
          <StyledLink to="/about">
            <FaUserSecret /> <span>About</span>
          </StyledLink>
        </p>
      </div>
      <div>
        <p>
		  <StyledLink to="/blog">
			<FaBook /> <span>Blog</span>
		  </StyledLink>
		</p>
		<p>
          <StyledHref
            href="mailto:max@bonnef.in">
            <FaEnvelope /> <span>Contact</span>
          </StyledHref>
        </p>
      </div>
    </MenuWrapper>
  </Sidebar>
)

export default SidebarComponent;
